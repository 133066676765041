import React from 'react';
import './comp.css';

import { useState, useEffect } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import 'swiper/swiper-bundle.css';

// Import Swiper styles
import 'swiper/css/effect-coverflow';
import 'swiper/css/effect-fade';
import 'swiper/css';

import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, EffectCoverflow,Navigation,Autoplay, EffectFade} from 'swiper/modules';
import { FadeInWhenVisible } from './FramerModules';


const Gallery = () => {
  const images = require.context('../assets/Gallery/', false );
  const imageList = images.keys().map(image => images(image));
  console.log(imageList)

  const [pv, letPv] = useState(window.innerWidth <= 450 ? 1 : 3);

  useEffect(() => {
    const handleResize = () => {
      letPv(window.innerWidth <= 450 ? 1 : 3);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  return (
    <div>
        <div className=''>
            <h1 className='p-10 text-center font-lexend font-semibold text-xl md:text-3xl'>Gallery</h1>
            <div className="px-10 md:pt-10 space-y-10">
              <Swiper
                modules={[EffectCoverflow,Autoplay, Pagination]}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false
                }}
                effect={'coverflow'}                
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={'auto'}
                coverflowEffect={{
                  rotate: 50,
                  stretch: 0,
                  depth: 100,
                  modifier: 1,
                  slideShadows: true,
                }}
                pagination={true}
          
                className="mySwiper"
              >
                  {imageList.map((image, index) => (
                      <SwiperSlide><img key={index} src={image} className="item" alt={`image-${index}`} /></SwiperSlide>
                  ))}
              </Swiper>
              <FadeInWhenVisible>
                <div className='md:flex hidden justify-center'>
                  <p 
                    className='text-center px-10 font-lexend font-bold text-3xl'
                    style={{
                      boxShadow:'.2rem .2rem black',
                      width:'fit-content',
                      border: '2px solid black',
                      borderRadius: '100px 0 100px 0',
                      background:'yellow'
                    }}
                    > 
                    Vijayadasami Festival with Kids and Their Parents at GKC 
                  </p>
                </div>
              </FadeInWhenVisible>

              <div className='flex md:hidden lg:hidden xl:hidden'>
              <Swiper
                spaceBetween={30}
                effect={'fade'}
                navigation={true}
                pagination={{
                  clickable: true,
                }}
                autoplay = {{
                  delay:2500,
                  disableOnInteraction:false
                }}
                modules={[EffectFade,Autoplay, Navigation, Pagination]}
                className="mySwiper"
              >
                {imageList.map((image, index) => (
                  <SwiperSlide style={{height:'500px'}} ><img key={index} src={image} className="item object-cover h-[500px]" alt={`image-${index}`} /></SwiperSlide>
                ))}
              </Swiper>
              
              </div>              
            </div>
        </div>
    </div>
  )
}

export default Gallery
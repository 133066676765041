import React from 'react';
import Aboutimg from '../assets/Aboutimg.webp';
import Mobileabout from '../assets/Mobileabout.jpg';

const About = () => {
  return (
    <div className='md:pt-12 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 items-center'>
      <h1 className='p-10 text-center font-lexend font-semibold text-xl md:text-3xl'>Who are we?</h1>
      <div className='z-50 md:grid md:grid-cols-2 about object-contain rounded-3xl md:bg-white'>
        <div className='p-6 md:p-10 text-justify sm:backdrop-blur-sm md:backdrop-blur-0'>
          {/*<h1 className='text-2xl font-bold'>About</h1>*/}
          <p className='text-white md:text-gray-700 text-base md:text-base font-lexend md:font-semibold'>
          Welcome to Gokulam Kidz Care, your trusted child daycare in Mylapore, Chennai. Catering to the needs of working parents, our center provides a secure and stimulating environment for 25+ children. 
          <br /><br />
          Equipped with advanced projectors, we engage young minds through interactive learning in a vibrant, child-friendly setting that encourages creativity and exploration. Our dedicated mentors and staff are committed to your child's well-being, fostering growth and development. 
          <br /><br />
          At Gokulam Kidz Care, we prioritize safety, holistic development, and open communication. Choose us for a nurturing second home where your child can thrive. Join our community at the heart of Mylapore for a journey of growth and discovery.          </p>
        </div>

        <div className='hidden md:block'>
          <img src={Aboutimg} alt="Diwali Picture" width={'auto'} style={{float: 'right', borderRadius:'0px 24px 24px 0px'}}/>
        </div>
      </div>
    </div>
  )
}

export default About
import React from 'react';
import { FadeInWhenVisible, Pop } from './FramerModules';
import Nav from './Navbar';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import CallContact from '../assets/CallContact.svg';
import Location from '../assets/Location.svg';



function createHeart() {
  const heart = document.createElement('div');
  heart.classList.add('heart');
  heart.style.zIndex = -10;
  heart.style.opacity = 0.5;
  heart.style.objectFit = 'contain';
  heart.style.left = Math.random() * 100 + "vw";
  heart.style.animationDuration = Math.random() * 2 + 3 + "s";
  heart.innerText = '💖';
  document.body.appendChild(heart);
  
  setTimeout(() => {
              heart.remove();
              }, 5000);
}

setInterval(createHeart, 2000);

function newButt(Children, sec, icon){
  return(
    <button className="btn" onClick={scrollToSection(sec)}>
    <span className="icon">
        <img src={icon} viewBox="0 0 175 80" width="30" height="30">
        </img>
    </span>
    <span class="text">{Children}</span>
</button>
  )
}


function Butt(Children, sec) {
  return(
    <button onClick={scrollToSection(sec)} className="butts md:w-48 md:h-12 rounded-2xl bg-sky-950 text-sky-400 border border-sky-400 border-b-4 font-medium overflow-hidden relative px-4 py-2 hover:brightness-150 hover:border-t-4 hover:border-b active:opacity-75 outline-none duration-300 group">
  <span className="butts bg-sky-400 shadow-sky-400 absolute -top-[150%] left-0 inline-flex w-80 h-[5px] rounded-2xl opacity-50 group-hover:top-[150%] duration-500 shadow-[0_0_10px_10px_rgba(0,0,0,0.3)]"></span>
  {Children}
</button>
  )
}

// onclick function to go to a section when a button is clicked
function scrollToSection(section) {
  return function() {
    if (section.startsWith('#')){
      document.querySelector(section).scrollIntoView({ behavior: 'smooth' });
    }
    else{
      window.open(section, "_blank");
    }
    
  };
}



const Homepage = () => {
  return (
    <div className='h-screen relative homepage overflow-hidden'>
    <Nav />
    {/*<img src={KidsPlaying} alt="KidsPlaying" className='hidden md:w-[500px] md:flex absolute md:-left-0 md:bottom-0' />
    <img src={KuttiKrishna} alt="Kutti Krishna" className='hidden md:w-48 z-10 md:flex absolute transition-all lg:top-0 md:-left-0' />
    <img src={Wave} alt="Background Image" className="block h-[50%] -z-10 transition-all md:flex lg:w-[100%] md:h-[45%] background-img bottom-0"/>
    <img src={Krishna} alt="Krishna Shadow" className='block xs:transform xs:translate-x-1/2 md:translate-x-0 opacity-10 z-10 w-96 md:w-96 md:flex absolute bottom-0 md:-right-0 ' />
    <div className='flex justify-center absolute md:hidden -bottom-20'>
       <ChildLottie />
      </div>*/}
    <div className="transform translate-y-1/3 md:translate-y-0 relative h-[91%] flex flex-col md:justify-center items-center">
      <div className=''>
      <FadeInWhenVisible>
        {/*<div className='text-center z-0 md:mb-5'>
            <h1 id='text' className='text-black font-extrabold font-[kids] uppercase text-6xl md:text-6xl lg:text-9xl tracking-widest'>Gokulam</h1>
            <p 
              className='text-5xl md:text-5xl font-[Tangerine] text-black'>
                Kidz Care
            </p>
        </div>*/}
      </FadeInWhenVisible>
      <div className='butts p-5 flex flex-row items-end h-[40vh] md:h-0 lg:pb-0 space-x-5 md:items-center lg:align-middle'>
          {newButt("Contact", "#Contact", CallContact)}
          {newButt("Location", "https://maps.app.goo.gl/ZMGTKn15RStEq7So8", Location)}
      </div>
      </div>
    </div>
    {/*<div class="heart line absolute"></div>*/}
    </div>
  )
}

export default Homepage
import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { IoIosMail } from "react-icons/io";
import { FaMobileAlt } from "react-icons/fa";




const Gmap = () => {
  return(
    <iframe title='Location' className=' h-full w-full' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.3240562039227!2d80.13803942476125!3d12.951103887362377!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525fad70515037%3A0x19f614dee943fd65!2sChrompet%20Train%20Station%2C%20Railway%2C%20Radha%20Nagar%2C%20Chromepet%2C%20Chennai%2C%20Tamil%20Nadu%20600044!5e0!3m2!1sen!2sin!4v1702148235459!5m2!1sen!2sin"  style={{border:0, borderRadius:'10px 10px 10px 10px'}} allowfullscreen={""} loading={"lazy"} referrerpolicy={"no-referrer-when-downgrade"}></iframe>
  )
}

const contactInfo = [
  {logo: <IoIosMail />, text: 'gokulamkidz@gmail.com'},
  {logo: <FaMobileAlt />, text: '9941422467'}
]

const renderInfo = (index, car) =>{
  return(
    <div className="logo flex flex-col justify-center items-center space-x-0 md:space-x-5 p-5">
      <div className="text-center text-3xl">
        {car.logo}
      </div>
      <div className="text-center font-lexend">
        <p>{car.text}</p>
      </div>
    </div>
  )
}

const Contact = () => {

  const formRef = useRef();

  const [error, setError] = useState(false);
  const [Success, setSuccess] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_p4iz3s4', 'template_4p059o5', formRef.current, 'Yt2o1xfE_TaAxOe5W')
      .then((result) => {
        setSuccess(true)
        resetMail();
      }, (error) => {
        setError(true);
      });
  };

  const resetMail = () => {
    formRef.current.reset();
  }

  return (
    <div id="Contact" className="md:max-w-7xl mx-0  lg:px-8 flex flex-col items-center justify-center mb-10 md:mb-10">
        <h1 className='p-4 md:p-10 text-center font-lexend font-semibold text-xl md:text-3xl'>Get In Touch</h1>
        <div className="contact flex flex-wrap md:flex-nowrap space-x-0 md:space-x-6 md:h-[500px] mx-5">
            <div className="md:flex md:flex-col md:justify-center md:items-center w-full md:w-1/2 object-contain">
              <div className='flex justify-center'>
                {contactInfo.map((info,index) => renderInfo(index, info))}
              </div>
              <Gmap />
            </div>
            <div className="w-fit md:w-1/2 p-6 flex flex-col items-center justify-center">
                <form className="formey backdrop-blur-0 md:backdrop-blur-md space-y-4 p-10" onSubmit={sendEmail} ref={formRef}>
                  <input required name='Name' type="text" placeholder="Name" className="w-full p-2 border border-gray-300 rounded" />
                  <input required name='Email' type="email" placeholder="Email" className="w-full p-2 border border-gray-300 rounded" />
                  <input required name='Mobile Number' type="tel" placeholder="Mobile Number" className="w-full p-2 border border-gray-300 rounded" />
                  <textarea required name='Message' placeholder="Message" className="w-full p-2 border border-gray-300 rounded" rows="5"></textarea>
                  <button type="submit" className="w-full p-2 bg-blue-500 text-white rounded">Submit</button>
                  {error && "Error"}
                  {Success && "Thank you for your message. We'll get back to you as soon as possible."}
                </form>
            </div>
        </div>
    </div>
  );
};

export default Contact;
import React, { useState, useEffect } from 'react';
import './App.css';
import Homepage from './components/Homepage';
import About from './components/About';
import Gallery from './components/Gallery';
import Contact from './components/Contact';
import Reviews from './components/Reviews';


function App() {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.pageYOffset > 0) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div className="App">
      <section className='h-screen'>
        <Homepage />
      </section>
      <section id='about' className='h-screen'>
        <About />
      </section>
      {/*<section id='Infra'>Infrastructure</section>
      <section id='Fests'>Fests and Events</section>*/}
      <section id='Gallery' className='h-screen'>
        <Gallery />
      </section>
      {/*<section id='Reviews' className=''>
        <Reviews />
    </section>*/}
      <section id='Contact' className='h-screen flex justify-center items-center'>
        <Contact />
      </section>
      {showButton && (
          <button onClick={scrollToTop} style={{zIndex:10, position: 'fixed', bottom: '10px', right: '10px' }}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12">
              <path strokeLinecap="round" strokeLinejoin="round" d="M15 11.25l-3-3m0 0l-3 3m3-3v7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </button>
      )}
    </div>
  );
}

export default App;
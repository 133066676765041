import React from 'react';
import { motion } from 'framer-motion';

export function FadeInWhenVisible({children}) {
    return (
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: false }}
        transition={{ duration: 0.5 }}
        variants={{
          visible: { opacity: 1, scale: 1 },
          hidden: { opacity: 0, scale: 1 }
        }}
      >
        {children}
      </motion.div>
    );
  }

export function Pop({children}) {
    return (
        <motion.div
        animate={{ x: 100 }}
        transition={{ ease: "linear",
        duration: 2,
        x: { duration: 1 }}}
        >
        {children}
        </motion.div>
    );
  }

export function Carousel({children}){
  return(
    <motion.div className="carousel">
      <motion.div
      className='inner-carousel'
          drag="x"
          dragConstraints={{right: 0, left:-1100}}
          dragTransition={{ bounceStiffness: 600, bounceDamping: 8 }}
      >
        {children}
      </motion.div>
    </motion.div>
  )
}